import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { BehaviorSubject, Observable, Subject, combineLatest, map, merge, of, scan, startWith, tap } from "rxjs";
import { ISkill } from "../models/skill";
import { environment } from "../../environments/environment";
import { toSignal } from "@angular/core/rxjs-interop";

@Injectable({
    providedIn: 'root'
})
export class SkillService {

    private enforceApiUrl = environment.enforceApiUrl;
    private skillsWithFilterSubject = new BehaviorSubject<string>('');
    public skillsWithFilter$ = this.skillsWithFilterSubject.asObservable();
    private skillWithAddSubject = new Subject<ISkill>();
    public addSkill$ = this.skillWithAddSubject.asObservable();
    constructor(private _httpClient: HttpClient) {

    }

   public skills$ = this._httpClient.get<ISkill[]>(this.enforceApiUrl + 'v1/'  + 'skill/' +  'organization' + '/' + environment.organizationId);     
   public addedSkill = signal<ISkill>({id:'', name:'', description:''});  
   public isSkillAdded = signal<boolean>(false);
   public updatedSkill = signal<ISkill>({id:'', name:'', description:''});  
   public isSkillUpdated= signal<boolean>(false);
   public deletedSkill = signal<ISkill>({id:'', name:'', description:''});         
   public isSkillDeleted = signal<boolean>(false);    
   public skills = signal<ISkill[]>([])                   
    
    skillsAddSkill$ = merge([
        this.skills$,
        this.addSkill$
    ]).pipe(
        scan((skills, value) =>
        (value instanceof Array) ? [...value] : [...skills, value], [] as ISkill[])
    )


    searchSkill(skillFilter:string){
        
    }

    skillBySkillId(skillId:number){
        return this._httpClient.get<ISkill>(this.enforceApiUrl + 'v1/' + 'skill' + '/' + skillId)
                        .pipe(
                            tap(data => console.log('skill: ' + JSON.stringify(data)))
                        );
    }
    updateSkill(skill:ISkill){
        return this._httpClient.put<ISkill>(this.enforceApiUrl + 'v1/' + 'skill/'+skill.id + '/organization/'+environment.organizationId , skill);
    }

    saveSkill(skill:ISkill){
        return this._httpClient.post<ISkill>(this.enforceApiUrl + 'v1/' + 'skill/' + environment.organizationId, skill);
    }

    deleteSkill(skillId:string){
        return this._httpClient.delete<boolean>(this.enforceApiUrl + 'v1/' + 'skill/'+environment.organizationId + '/' + skillId);
    }

}
